import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  pageFragments,
  residencePage,
  roomPage,
  apartmentsPage,
  apartmentsHome,
} from '../fragments/pagePreview';
import Page from '../templates/innerPage';
import Home from '../templates/page';
import RoomPage from '../templates/roomPage';
import ResidencePage from '../templates/residencePage';

const preview = () => {
  const [page, setPage] = useState(null);
  const [home, setHome] = useState(null);
  const [room, setRoom] = useState(null);
  const [residence, setResidence] = useState(null);
  const isBrowser = typeof window !== 'undefined';
  const queryParams = new URLSearchParams(isBrowser && window.location.search);
  const [message, setMessage] = useState('Loading Preview');
  const langCode = queryParams.get('lang') || 'en-US';
  const id = queryParams.get('id');

  useEffect(() => {
    const loadPage = (type) => {
      let q = '';
      switch (type) {
        case 'apartmentsHome':
          q = `
          ${pageFragments}
          ${apartmentsHome}
            query ($id: ID!, $lang: String!) {
              apartmentsHome(id: $id, culture: $lang, preview: true) {
                  ...Home
                }
            }
          `;
          break;
        case 'apartmentsPage':
          q = `
            ${pageFragments}
            ${apartmentsPage}
            query ($id: ID!, $lang: String!) {
              apartmentsPage(id: $id, culture: $lang, preview: true) {
                  ...Page
                }
            }
          `;
          break;
        case 'apartmentsRoom':
          q = `
              ${pageFragments}
              ${roomPage}
              query ($id: ID!, $lang: String!) {
                apartmentsRoom(id: $id, culture: $lang, preview: true) {
                    ...RoomPage
                  }
              }
            `;
          break;
        case 'residencePage':
          q = `
                ${pageFragments}
                ${residencePage}
                query ($id: ID!, $lang: String!) {
                  residencePage(id: $id, culture: $lang, preview: true) {
                      ...ResidencePage
                    }
                }
              `;
          break;
        default:
          setMessage('Invalid document type');
          return;
      }
      const options = {
        method: 'POST',
        url: 'https://graphql.umbraco.io',
        headers: {
          'Content-Type': 'application/json',
          'Umb-Project-Alias': process.env.GATSBY_UMBRACO_ENVIRONMENT,
          'Api-Key': process.env.GATSBY_PREVIEW_API_KEY,
        },
        data: {
          query: q,
          variables: {
            id,
            lang: langCode,
          },
        },
      };
      axios
        .request(options)
        .then((response) => {
          console.log(response.data);
          if (type === 'apartmentsHome') {
            setHome({ umbraco: { apartmentsHome: response.data.data.apartmentsHome } });
          }
          if (type === 'apartmentsPage') {
            setPage({ umbraco: { apartmentsPage: response.data.data.apartmentsPage } });
          }
          if (type === 'apartmentsRoom') {
            setRoom({ umbraco: { apartmentsRoom: response.data.data.apartmentsRoom } });
          }
          if (type === 'residencePage') {
            setResidence({ umbraco: { residencePage: response.data.data.residencePage } });
          }
        })
        .catch(() => {
          // console.error(error);
        });
    };
    const getPageType = async () => {
      const options = {
        method: 'POST',
        url: 'https://graphql.umbraco.io',
        headers: {
          'Content-Type': 'application/json',
          'Umb-Project-Alias': process.env.GATSBY_UMBRACO_ENVIRONMENT,
          'Api-Key': process.env.GATSBY_PREVIEW_API_KEY,
        },
        data: {
          query: `
              query ($id: ID!, $lang: String!) {
                  content(id: $id, culture: $lang, preview: true) {
                    contentTypeAlias
                  }
              }
              `,
          variables: {
            id,
            lang: langCode,
          },
        },
      };
      axios
        .request(options)
        .then((response) => {
          loadPage(response.data.data.content.contentTypeAlias);
        })
        .catch(() => {
          // console.error(error);
        });
    };

    if (!id) {
      setMessage('No id found');
      return;
    }
    getPageType();
  }, []);
  return (
    <>
      {home ? (
        <Home pageContext={{ langCode: langCode.split('-')[0], lang: langCode }} data={home} />
      ) : null}
      {page ? (
        <Page pageContext={{ langCode: langCode.split('-')[0], lang: langCode }} data={page} />
      ) : null}
      {room ? (
        <RoomPage pageContext={{ langCode: langCode.split('-')[0], lang: langCode }} data={room} />
      ) : null}
      {residence ? (
        <ResidencePage
          pageContext={{ langCode: langCode.split('-')[0], lang: langCode }}
          data={residence}
        />
      ) : null}
      {!home && !page && !room && !residence ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', hight: '100%' }}
        >
          <h1>{message}</h1>
        </div>
      ) : null}
    </>
  );
};

export default preview;
