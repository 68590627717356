export const pageFragments = `
fragment Link on Link {
  name
  target
  url
  udi
}
fragment Image on MediaWithCrops {
  url
}
fragment NavigationItem on NavigationItem {
  subtitle
  color
  link {
    ...Link
  }
  itemChildren {
    content {
      ... on NavigationItem {
        subtitle
        link {
          ...Link
        }
        itemChildren {
          content {
            ... on NavigationItem {
              subtitle
              link {
                ...Link
              }
            }
          }
        }
      }
    }
  }
}
fragment ApartmentsSettings on ApartmentsSettings {
  googleAnalyticsCode
  googleMapsAPICode
  lightLogo {
    ...Image
  }
  footerLogo {
    ...Image
  }
  contentTypeAlias
  copyrightText
  footerText
  termsAndConditionsLink {
    ...Link
  }
  privacyPolicyLink {
    ...Link
  }
  reservationLink {
    ...Link
  }
  footerMenu {
    content {
      ... on LinkGroup {
        urls {
          ...Link
        }
      }
    }
  }
  mainNavigation {
    content {
      ...NavigationItem
    }
  }
  translations {
    content {
      ... on Translation {
        key
        value
      }
    }
  }
}
fragment ImageCard on ImageCards {
  title
  subtitle
  text
  className
  image {
    url
  }
  link {
    ...Link
  }
}
fragment TwoColumnsImageText on ApartmentsTwoColumnsImageText {
  subtitle
  title
  text
  backgroundImage {
    url
  }
  imageCardsList {
    content {
      ... on ImageCards {
        ...ImageCard
      }
    }
  }
}
fragment AboutUs on ApartmentsAboutUs {
  cardSubtitle
  cardText
  cardTitle
  contentTypeAlias
  sectionSubtitle
  sectionText
  sectionTitle
  topBackgroundImage {
    url
  }
  cardLogo {
    url
  }
  cardLink {
    ...Link
  }
  bottomBackgroundImage {
    url
  }
}

fragment PromotionIntro on ApartmentsPromotionIntro{
  subtitle
  text
  title
  videoUrl
  topBackgroundImage {
    url
  }
}

fragment PromotionTitleWithText on ApartmentsPromotionTitleWithText {
  text
  title
}

fragment ImageWithLink on ImageWithLink {
  image {
    ...Image
  }
  link {
    ...Link
  }
  title
}

fragment Partners on ApartmentsPartners {
  partnerslist {
    content {
      ...ImageWithLink
    }
  }
}

fragment FeaturedPageCard on FeaturedPageCard {
  text
  title
  image {
    url
  }
  link {
    ...Link
  }
  externalLink
  className
}
fragment FeaturedPages on ApartmentsFeaturedPages {
  featuredPagesList {
    content {
      ... on FeaturedPageCard {
        ...FeaturedPageCard
      }
    }
  }
}
fragment BookingForm on ApartmentsBookingForm {
  title
  text
  reserveLink {
    ...Link
  }
  otherLinks {
    ...Link
  }
  formHeadline
  umbracoFormId
  backgroundImage {
    url
  }
}
fragment NeighborhoodItem on ApartmentsNeighborhoodItem {
  text
  title
  distance
  duration
  link {
    ...Link
  }
}
fragment AmenityItem on ApartmentsAmenityItem {
  iconImage {
    url
  }
  subtitle
  title
  link {
    ...Link
  }
}
fragment ServicesList on ApartmentsServicesList {
  text
  title
  bottomBackgroundImage {
    url
  }
  servicesList {
    content {
      ... on ApartmentsAmenityItem {
        ...AmenityItem
      }
    }
  }
}
fragment ContactUsForm on ApartmentsContactUsForm {
  title
  text
  umbracoFormId
}
fragment FeaturedSlider on ApartmentsFeaturedSlider {
  subtitle
  title
  featuredSlider {
    url
    ... on ApartmentsRoom {
      featuredImage {
        url
      }
      title
      description
    }
    ... on ResidencePage {
      featuredImage {
        url
      }
      title
      description
    }
  }
}
fragment Location on ApartmentsLocation {
  address
  generalEmail
  title
  subtitle
  reservationEmail
  phone
  backgroundImage {
    url
  }
  contactUsLink {
    ...Link
  }
  googleMapsLink {
    ...Link
  }
  mapImage {
    url
  }
  neighborhoodMapLink {
    ...Link
  }
}
fragment AmenitiesAndServices on ApartmentsAmenitiesAndServices {
  subtitle
  text
  title
  backgroundImage {
    url
  }
  amenitiesList {
    content {
      ... on ApartmentsAmenityItem {
        ...AmenityItem
      }
    }
  }
}
fragment FeaturedPromotions on ApartmentsFeaturedPromotions {
  subtitle
  title
  parentLink {
    ...Link
  }
}

fragment RoomsList on ApartmentsRoomsList {
  subtitle
  text
  title
  videoUrl
  bottomBackgroundImage {
    url
  }
  topBackgroundImage {
    url
  }
  selectResidencesOrApartments {
    url
    ... on ApartmentsRoom {
      featuredImage {
        url
      }
      title
      description
    }
    ... on ResidencePage {
      featuredImage {
        url
      }
      title
      description
    }
  }
}



fragment AttractionItem on ApartmentsAttractionItem {
  distanceInKM
  distanceInTime
  title
  subtitle
  text
  link {
    ...Link
  }
  image {
    url
  }
}
fragment PromotionList on ApartmentsPromotionList {
  subtitle
  text
  videoUrl
  title
  topBackgroundImage {
    url
  }
  bottomBackgroundImage {
    url
  }
}
fragment Neighborhoods on ApartmentsNeighborhoods {
  subtitle
  title
  backgroundImage {
    url
  }
  neighborhoodList {
    content {
      ... on ApartmentsNeighborhoodItem {
        ...NeighborhoodItem
      }
    }
  }
}
fragment OtherDiningCarousel on ApartmentsDiningCarousel {
  subtitle
  text
  title
  backgroundImage {
    url
  }
  slides {
    content {
      ...ImageCard
    }
  }
}
fragment Attractions on ApartmentsAttractions {
  text
  title
  backgroundImage {
    url
  }
  attractionsList {
    content {
      ... on ApartmentsAttractionItem {
        ...AttractionItem
      }
    }
  }
}
fragment HomePageBanner on ApartmentsHomePageBanner {
  bannerVideo
  text
}
fragment HtmlContentBlock on HtmlContent {
  htmlContent
  title
}
fragment VerticalImageCards on ApartmentsVerticalImageCards {
  bottomBackgroundImage {
    url
  }
  subtitle
  text
  title
  topBackgroundImage {
    url
  }
  verticalImageCardList {
    content {
      ...ImageCard
    }
  }
  widerCardTextContainer
}
fragment FloorplanBlock on ApartmentsFloorplanBlock {
  title
  text
  furnishedImage {
    url
  }
  unfurnishedImage {
    url
  }
}
`;
export const residencePage = `
fragment ResidencePage on ResidencePage {
  id
  name
  url
  title
  description
  metadataTitle
  metadataDescription
  metadataKeywords
  disableSearchEngineIndexing
  roomDescription
  roomDetails
  includedServices
  additionalBenefits
  businessAndGuestServices {
    content {
      ... on ApartmentsAmenityItem {
        ...AmenityItem
      }
    }
  }
  leasingOptions
  gallery {
    url
  }
  featuredImage {
    url
  }
  featuredVideo
  headerIntro

  settings: ancestors(where: { contentTypeAlias: "apartmentsHome" }) {
    items {
      children(where: { contentTypeAlias: "ApartmentsSettings" }) {
        items {
          ... on ApartmentsSettings {
            ...ApartmentsSettings
          }
        }
      }
    }
  }

  cssClass
  apartmentsBody {
    content {
      contentTypeAlias
      ...HomePageBanner
      ...FeaturedPromotions
      ...BookingForm
      ...FeaturedSlider
      ...AmenitiesAndServices
      ...FeaturedPages
      ...PromotionList
      ...RoomsList
      ...VerticalImageCards
      ...ServicesList
      ...TwoColumnsImageText
      ...OtherDiningCarousel
      ...Attractions
      ...Neighborhoods
      ...Location
      ...AboutUs
      ...PromotionIntro
      ...PromotionTitleWithText
      ...Partners
      ...ContactUsForm
      ...FloorplanBlock
      ...HtmlContentBlock
    }
  }
  ancestors {
    items {
      id
    }
  }
}`;
export const roomPage = `
fragment RoomPage on ApartmentsRoom {
  id
  name
  url
  title
  description
  metadataTitle
  metadataDescription
  metadataKeywords
  disableSearchEngineIndexing
  roomDescription
  roomDetails
  includedServices
  additionalBenefits
  businessAndGuestServices {
    content {
      ... on ApartmentsAmenityItem {
        ...AmenityItem
      }
    }
  }
  formID
  gallery {
    url
  }
  featuredImage {
    url
  }
  featuredVideo
  headerIntro

  settings: ancestors(where: { contentTypeAlias: "apartmentsHome" }) {
    items {
      children(where: { contentTypeAlias: "ApartmentsSettings" }) {
        items {
          ... on ApartmentsSettings {
            ...ApartmentsSettings
          }
        }
      }
    }
  }

  cssClass
  apartmentsBody {
    content {
      contentTypeAlias
      ...HomePageBanner
      ...FeaturedPromotions
      ...BookingForm
      ...FeaturedSlider
      ...AmenitiesAndServices
      ...FeaturedPages
      ...PromotionList
      ...RoomsList
      ...VerticalImageCards
      ...ServicesList
      ...TwoColumnsImageText
      ...OtherDiningCarousel
      ...Attractions
      ...Neighborhoods
      ...Location
      ...AboutUs
      ...PromotionIntro
      ...PromotionTitleWithText
      ...Partners
      ...ContactUsForm
      ...FloorplanBlock
      ...HtmlContentBlock
    }
  }
  ancestors {
    items {
      id
    }
  }
}`;
export const apartmentsPage = `
fragment Page on ApartmentsPage {
  id
  name
  url
  title
  description
  metadataTitle
  metadataDescription
  metadataKeywords
  disableSearchEngineIndexing
  socialMediaImage {
    ...Image
  }
  settings: ancestors(where: { contentTypeAlias: "apartmentsHome" }) {
    items {
      children(where: { contentTypeAlias: "ApartmentsSettings" }) {
        items {
          ... on ApartmentsSettings {
            ...ApartmentsSettings
          }
        }
      }
    }
  }
  cssClass
  apartmentsBody {
    content {
      contentTypeAlias
      ...HomePageBanner
      ...FeaturedPromotions
      ...BookingForm
      ...FeaturedSlider
      ...AmenitiesAndServices
      ...FeaturedPages
      ...PromotionList
      ...RoomsList
      ...VerticalImageCards
      ...ServicesList
      ...TwoColumnsImageText
      ...OtherDiningCarousel
      ...Attractions
      ...Neighborhoods
      ...Location
      ...AboutUs
      ...PromotionIntro
      ...PromotionTitleWithText
      ...Partners
      ...ContactUsForm
      ...HtmlContentBlock
      ...FloorplanBlock
    }
  }
  ancestors {
    items {
      id
    }
  }
}`;
export const apartmentsHome = `
fragment Home on ApartmentsHome {
  children {
    items {
      ... on ApartmentsSettings {
        ...ApartmentsSettings
      }
    }
  }
  id
  name
  url
  title
  description
  metadataTitle
  metadataDescription
  metadataKeywords
  disableSearchEngineIndexing
  socialMediaImage {
    ...Image
  }
  cssClass
  apartmentsBody {
    content {
      contentTypeAlias
      ...HomePageBanner
      ...FeaturedPromotions
      ...BookingForm
      ...FeaturedSlider
      ...AmenitiesAndServices
      ...FeaturedPages
      ...PromotionList
      ...RoomsList
      ...VerticalImageCards
      ...ServicesList
      ...TwoColumnsImageText
      ...OtherDiningCarousel
      ...Attractions
      ...Neighborhoods
      ...Location
      ...AboutUs
      ...PromotionIntro
      ...PromotionTitleWithText
      ...Partners
      ...ContactUsForm
      ...HtmlContentBlock
      ...FloorplanBlock
    }
  }
}`;
